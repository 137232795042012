import Vue from 'vue';
import App from './App.vue';
import 'amfe-flexible';
import axios from 'axios';
import Header from './components/header/index.vue';
import Loading from './components/loading/loading.vue';
import VueLuckyCanvas from '@lucky-canvas/vue';
Vue.use(VueLuckyCanvas);
Vue.component('Header', Header);
Vue.component('Loading', Loading);
Vue.prototype.$axios = axios;
// 引入重置样式表
import '@/assets/css/reset.css';
import less from 'less';
Vue.use(less);
// 适配
import 'amfe-flexible';

import { Area } from 'vant';

Vue.use(Area);
import { Toast } from 'vant';
Vue.use(Toast);
// import $ from "jquery";
//导入所有组件。
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
// import "../src/assets/js/rem";
// 引入路由文件
import router from '@/router/index.js';
// 引入vant 提供过的懒加载
import { Lazyload } from 'vant';
Vue.use(Lazyload);

Vue.config.productionTip = false;

//接口请求方式挂载
import api from '@/api';
Vue.prototype.$api = api;

//全局过滤器
Vue.filter('moneyFlrmat', function (value) {
  return `￥${value.toFixed(2)}元`;
});

//  复制
import Clipboard from 'clipboard';

import store from './store';
Vue.prototype.Clipboard = Clipboard;

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount('#app');
