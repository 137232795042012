import instance from '../utils/request'

// 轮播图
export function Banner(params) {
  return instance({
    url: '/api/webinfo/anyBanner',
    method: 'get',
    params,
  })
}

// Banner详情
export function anyNoticedetail(params) {
  return instance({
    url: '/api/webinfo/anyNoticedetail',
    method: 'get',
    params,
  })
}

// 找回支付密码
export function passwordForget(data) {
  return instance({
    url: '/api/user/passwordForget',
    method: 'post',
    data,
  })
}

// 公告类型
export function getNoticeTypeList(params) {
  return instance({
    url: '/api/webinfo/getNoticeTypeList',
    method: 'get',
    params,
  })
}

// 推荐列表
export function Recommend(params) {
  return instance({
    url: '/api/webinfo/anyRecommend',
    method: 'get',
    params,
  })
}

//首页分类列表
export function anyHomeShopType() {
  return instance({
    url: `/api/shop/anyHomeShopType`,
    method: 'get',
  })
}

// 首页商品列表
export function Goodslist(token) {
  return instance({
    url: `api/shop/anyGoodslist?pageindex=${token.pageindex}&pagesize=${token.pagesize}`,
    method: 'get',
    params: token,
  })
}

// 公告列表信息
export function getNotice(params) {
  return instance({
    url: `/api/webinfo/getNotice`,
    method: 'get',
    params,
  })
}

// 市场导航栏
export function anyTypelist(params) {
  return instance({
    url: '/api/shop/anyTypelist',
    method: 'get',
    params,
  })
}

// 市场导航栏
export function pendingPayment(params) {
  return instance({
    url: '/api/juhe/Pending_payment',
    method: 'post',
    params,
  })
}

// 首页创作人以及归属人
export function anyGoodsUserhaveList(params) {
  return instance({
    url: `api/shop/anyGoodsUserhaveList`,
    method: 'get',
    params,
  })
}

// 邮箱登录
export function login(params) {
  return instance({
    url: 'api/login/anyLogin',
    method: 'post',
    params,
  })
}

// 获取图片验证码
export function getGraphicalCode(params) {
  return instance({
    url: '/api/login/anyCaptchav2',
    method: 'post',
    params,
    responseType: 'blob',
  })
}

// 手机忘记密码,发送验证码
export function anyCheckcodeforget(params) {
  return instance({
    url: '/api/login/anyCheckcodeforget',
    method: 'post',
    params,
  })
}

// 邮箱忘记密码,发送验证码
export function anyCheckcodeforgetForEmail(params) {
  return instance({
    url: '/api/login/anyCheckcodeforgetForEmail',
    method: 'post',
    params,
  })
}

// 手机忘记密码,更改密码
export function affirm(params) {
  return instance({
    url: '/api/login/anyPasswordchange',
    method: 'post',
    params,
  })
}
// 获取机构列表
export function getUserAgentInfo(params) {
  return instance({
    url: '/api/login/getUserAgentInfo',
    method: 'get',
    params,
  })
}
// 获取验证码
export function getVerification(params) {
  return instance({
    url: '/api/login/anyRegistercheckcode',
    method: 'post',
    params,
  })
}

// 注册
export function anyRegister(params) {
  return instance({
    url: '/api/login/anyRegister',
    method: 'post',
    params,
  })
}

// 邮箱注册
export function emailSubmit(params) {
  return instance({
    url: '/api/login/anyEmailRegister',
    method: 'post',
    params,
  })
}

// 获取邮箱验证码
export function getEmailVerification(params) {
  return instance({
    url: '/api/login/anySendEmail',
    method: 'post',
    params,
  })
}

// 获取邮箱图片验证码
export function getEmailGraphicalCode(params) {
  return instance({
    url: '/api/login/anyCaptchav3',
    method: 'post',
    params,
    responseType: 'blob',
  })
}
// 手机验证码登录
export function loginTel(params) {
  return instance({
    url: 'api/login/anyFastlogin',
    method: 'post',
    params,
  })
}

// 手机验证码发送
export function loginTU(params) {
  return instance({
    url: 'api/login/anyFastlogincheckcode',
    method: 'post',
    params,
  })
}

// 商品详情
export function anyGoodsdetail(params) {
  return instance({
    url: `api/shop/anyGoodsdetail?id=${params.id}`,
    method: 'get',
    params,
  })
}

// 市场列表
export function anyGoodmarketlist(params) {
  return instance({
    url: `/api/shop/anyGoodmarketlist`,
    method: 'get',
    params,
  })
}

// 市场列表搜索
export function getMarketSearchResult(params) {
  return instance({
    url: `/api/shop/getMarketSearchResult`,
    method: 'get',
    params,
  })
}

// 市场详情
export function anyGetGoodmarketInfoByid(params) {
  return instance({
    url: `/api/shop/anyGetGoodmarketInfoByid`,
    method: 'get',
    params,
  })
}

// 设置----实名认证
export function anyIdcardadd(params) {
  return instance({
    url: `/api/user/anyIdcardadd?params=${params.token}`,
    method: 'post',
    params,
  })
}
// 基本设置------平台协议
export function anyInfo() {
  return instance({
    url: '/api/webinfo/anyInfo',
    method: 'get',
  })
}

// 更换头像
export function postImage(data, params) {
  return instance({
    url: `/api/file/postImage`,
    method: 'post',
    data,
    params,
  })
}

//实名认证状态
export function userRealName(token) {
  return instance({
    url: `api/user/userRealName?token=${token.token}`,
    method: 'get',
    params: token,
  })
}
//个人资料
export function anyUserinfo(params) {
  return instance({
    url: `/api/user/anyUserinfo?token=${params.token}`,
    method: 'get',
    params,
  })
}

// 修改个人资料
export function anyUserinfoedit(params) {
  return instance({
    url: `/api/user/anyUserinfoedit?token=${params.token}`,
    method: 'post',
    params,
  })
}

//获取银行卡信息
export function anyBankname(params) {
  return instance({
    url: 'api/webinfo/anyBankname',
    method: 'get',
    params,
  })
}
//绑定银行卡
export function anyBankcardadd(params) {
  return instance({
    url: `api/user/anyBankcardadd?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 验证银行卡短信
export function anyVerifyBankcard(params) {
  return instance({
    url: `api/user/anyVerifyBankcard?token=${params.token}`,
    method: 'post',
    params,
  })
}
// 用户银行卡列表
export function anyBankcardlist(params) {
  return instance({
    url: `/api/user/anyBankcardlist?token=${params.token}`,
    method: 'post',
    params,
  })
}

//我的邀请人列表
export function getInvitationList(params) {
  return instance({
    url: `api/user/getInvitationList?pageindex=${params.pageindex}&pagesize=${params.pagesize}&token=${params.token}`,
    method: 'get',
    params,
  })
}

//安全中心-设置登录密码
export function set_up(params) {
  return instance({
    url: `api/user/set_up?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 安全中心 - 修改登录密码;
export function anyPassword(params) {
  return instance({
    url: `api/user/anyPassword?token=${params.token}`,
    method: 'post',
    params,
  })
}
// 设置支付密码
export function anyPaypassword(params) {
  return instance({
    url: `/api/user/anyPaypassword?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 修改支付密码
export function anyOriginPayPassword(params) {
  return instance({
    url: `/api/user/anyOriginPayPassword?token=${params.token}`,
    method: 'post',
    params,
  })
}

//支付宝充值

export function recharge(params) {
  return instance({
    url: `/api/juhe/recharge?token=${params.token}`,
    method: 'post',
    params,
  })
}

//首页商品详情-----立即购买
export function detail(params) {
  return instance({
    url: `/api/goodsdrder/detail?token=${params.token}&id=${params.id}`,
    method: 'post',
    params,
  })
}

// 确认支付
export function payJuhe(params) {
  return instance({
    url: `/api/juhe/pay_juhe?token=${params.token}`,
    method: 'post',
    params,
  })
}

//藏品列表
export function anyHavegoodslist(params) {
  return instance({
    url: `/api/tradeAuth/anyHavegoodslist`,
    method: 'get',
    params,
  })
}

// 藏品详情
export function anyCollectionDetail(params) {
  return instance({
    url: `/api/user/anyCollectionDetail?token=${params.token}`,
    method: 'get',
    params,
  })
}
// 立即寄卖
export function ConsignSell(params) {
  return instance({
    url: `/api/juhe/Consign_Sell?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 立即寄卖实时监听
export function ServiceCharge(params) {
  return instance({
    url: `/api/goodsdrder/Service_Charge?token=${params.token}`,
    method: 'post',
    params,
  })
}

//确认寄卖
export function ConsignmentSell(params) {
  return instance({
    url: `/api/juhe/Consignment_Sell?token=${params.token}`,
    method: 'post',
    params,
  })
}

//确认寄卖
export function getUserhang(params) {
  return instance({
    url: `/api/user/getUserhang`,
    method: 'get',
    params,
  })
}

//退出登录
export function logout(params) {
  return instance({
    url: '/api/user/logout',
    method: 'get',
    params,
  })
}

//首页-----个人资料
export function Userinfo(message) {
  return instance({
    url: `/api/user/anyUserinfo?token=${message.token}`,
    method: 'get',
    params:message,
  })
}

//首页-----盲盒列表
export function blindBoxList(token) {
  return instance({
    url: `/api/shop/blindBoxList?pageindex=${token.pageindex}&pagesize=${token.pagesize}&key=${token.key}`,
    method: 'get',
    params: token,
  })
}

// 我的订单
export function goodsorder(params) {
  return instance({
    url: `/api/goodsorder/list?token=${params.token}`,
    method: 'post',
    params,
  })
}

//绑定支付宝
export function anyBindalipayaccount(params) {
  return instance({
    url: '/api/user/anyBindalipayaccount',
    method: 'post',
    params,
  })
}

//我的---钱包提现
export function WithdrawalBalance(params) {
  return instance({
    url: '/api/user/Withdrawal_balance',
    method: 'post',
    data: params,
  })
}

// 取消寄卖-----寄卖委托撤单;
export function anyCancel(params) {
  return instance({
    url: `/api/tradeAuth/anyCancel?token=${params.token}`,
    method: 'get',
    params,
  })
}

// 卜卦
export function createUserhang(params) {
  return instance({
    url: `/api/user/createUserhang?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 山河大学的生成图
export function getUserSpeciality(params) {
  return instance({
    url: `/api/user/getUserSpeciality`,
    method: 'get',
    params,
  })
}

//市场详情------全部编号
export function anyGetPositionListByGoodsid(params) {
  return instance({
    url: `/api/shop/anyGetPositionListByGoodsid?token=${params.token}`,
    method: 'get',
    params,
  })
}

// 市场详情-----确定订单
export function goodsorderDetail(params) {
  return instance({
    url: `/api/goodsorder/detail?token=${params.token}`,
    method: 'post',
    params,
  })
}

//开启盲盒
export function openBlindBox(params) {
  return instance({
    url: `/api/tradeAuth/newopenBlindBox?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 取消购买
export function cancelUserOrder(params) {
  return instance({
    url: `/api/goodsorder/cancelUserOrder?token=${params.token}`,
    method: 'get',
    params,
  })
}

// 充值完成列表
export function rechargeok(parmas) {
  return instance({
    url: `/api/goodsorder/rechargeok?token=${parmas.token}&pageindex=${parmas.pageindex}&pagesize=${parmas.pagesize}&source=${parmas.source}&date=${parmas.date}`,
    method: 'post',
    data: parmas,
  })
}

// 静默签协议
// export function getUsergrant(params) {
//   return instance({
//     url: `/api/user/getUsergrant?token=${params.token}`,
//     method: "get",
//     params,
//   });
// }

// 静默签协议
export function getopenBlindBoxList(params) {
  return instance({
    url: `/api/shop/getopenBlindBoxList?id=${params.id}`,
    method: 'get',
    params,
  })
}

//我的订单详情
export function detailsCleared(params) {
  return instance({
    url: `/api/juhe/details_cleared?token= ${params.token}`,
    method: 'post',
    params,
  })
}

// 盲盒详情
export function blindBoxDetail(params) {
  return instance({
    url: `/api/shop/getopenBlindBoxList?id=${params.id}`,
    method: 'get',
    params,
  })
}

//我得支付宝
export function anyAlipayaccount(params) {
  return instance({
    url: `/api/user/anyAlipayaccount?token=${params.token}`,
    method: 'get',
    params,
  })
}
// 寄卖协议
export function goodsorderButton(params) {
  return instance({
    url: `/api/goodsorder/Button?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 我的钱包余额，提现
export function withdrawal(params) {
  return instance({
    url: `/api/goodsorder/withdrawal?token=${params.token}`,
    method: 'post',
    params,
  })
}

// 获取订单状态
export function loop(params) {
  return instance({
    url: `/api/juhe/loop`,
    method: 'get',
    params,
  })
}

// 我的新藏品列表
export function userBuyShopList(params) {
  return instance({
    url: `/api/tradeAuth/userBuyShopList`,
    method: 'get',
    params,
  })
}

// 用户下持仓的编号
export function userPostionGoodNumberList(params) {
  return instance({
    url: `/api/tradeAuth/getUserPostionGoodNumberList`,
    method: 'get',
    params,
  })
}

// 获取海报
export function poster(params) {
  return instance({
    url: `/api/user/poster`,
    method: 'get',
    params,
  })
}

// 反馈
export function feedback(data) {
  return instance({
    url: `/api/user/anyFeedback`,
    method: 'post',
    data,
  })
}

// 平台合成公式列表
export function goodsComposeList(params) {
  return instance({
    url: `/api/newgoodsCompose/goodsComposeList`,
    method: 'get',
    params,
  })
}

// 平台合计公式详情
export function goodsComposeById(params) {
  return instance({
    url: `/api/newgoodsCompose/goodsComposeById`,
    method: 'get',
    params,
  })
}
// 平台合计公式详情1
export function goodsbatchComposeById(params) {
  return instance({
    url: `/api/newgoodsCompose/goodsbatchComposeById`,
    method: 'get',
    params,
  })
}

// 用户合成选择旗下商品
export function userGoodsCompose(params) {
  return instance({
    url: `/api/user/userGoodsCompose`,
    method: 'get',
    params,
  })
}

// 进行合成
export function userRunCompose(data) {
  return instance({
    url: `/api/user/newuserRunCompose`,
    method: 'post',
    data,
  })
}

// 进行合成1
export function userRunbatchCompose(data) {
  return instance({
    url: `/api/user/userRunbatchCompose`,
    method: 'post',
    data,
  })
}

// 用户合成记录
export function userComposeLogList(params) {
  return instance({
    url: `/api/user/userComposeLogList`,
    method: 'get',
    params,
  })
}

// 合成记录详情
export function getUserComposeLogInfo(params) {
  return instance({
    url: `/api/user/getUserComposeLogInfo`,
    method: 'get',
    params,
  })
}

// 热门
export function shopHotWordList(params) {
  return instance({
    url: `/api/shop/shopHotWordList`,
    method: 'get',
    params,
  })
}

// 是否弹窗
export function popup(data) {
  return instance({
    url: `/api/message/popup`,
    method: 'post',
    data,
  })
}
// 公告通知列表
export function noticeStatus(data) {
  return instance({
    url: `/api/user/noticeStatus`,
    method: 'post',
    data,
  })
}

// 订单通知列表
export function orderStatus(data) {
  return instance({
    url: `/api/user/orderStatus`,
    method: 'post',
    data,
  })
}
// 订单通知列表
export function clearAll(data) {
  return instance({
    url: `/api/user/clearAll`,
    method: 'post',
    data,
  })
}

// 未读公告消息状态修改
export function updateStatus(data) {
  return instance({
    url: `/api/user/updateStatus`,
    method: 'post',
    data,
  })
}

// 未读订单通知状态修改
export function updateOrderStatus(data) {
  return instance({
    url: `/api/user/updateOrderStatus`,
    method: 'post',
    data,
  })
}

// 未读订单通知状态修改
export function unread(data) {
  return instance({
    url: `/api/user/unread`,
    method: 'post',
    data,
  })
}

// 未读订单通知状态修改
export function userCheckGoodsListCompose(params) {
  return instance({
    url: `/api/user/newuserCheckGoodsListCompose`,
    method: 'get',
    params,
  })
}
// 未读订单通知状态修改
export function userCheckGoodsListCompose1(params) {
  return instance({
    url: `/api/user/userCheckGoodsListCompose`,
    method: 'get',
    params,
  })
}

// 每日签到
export function userSignDays(data) {
  return instance({
    url: `/api/user/userSignDays`,
    method: 'post',
    data,
  })
}

// 签到详情
export function userSignList(params) {
  return instance({
    url: `/api/user/userSignList`,
    method: 'get',
    params,
  })
}

// 签到详情
export function rewardWalletList(params) {
  return instance({
    url: `/api/user/rewardWalletList`,
    method: 'get',
    params,
  })
}

// 用户奖励红包金额
export function rewardWallet(params) {
  return instance({
    url: `/api/user/rewardWallet`,
    method: 'get',
    params,
  })
}

// 奖励转出到余额
export function rewardToUserMoney(data) {
  return instance({
    url: `/api/user/rewardToUserMoney`,
    method: 'post',
    data,
  })
}

// 申购列表
export function applyGoodsList(params) {
  return instance({
    url: `/api/shop/applyGoodsList`,
    method: 'get',
    params,
  })
}

// 申购详情
export function applyGoodsInfo(params) {
  return instance({
    url: `/api/shop/applyGoodsInfo`,
    method: 'get',
    params,
  })
}

// 申购详情
export function payChannel(params) {
  return instance({
    url: `/api/webinfo/payChannel`,
    method: 'get',
    params,
  })
}

// 申购确认订单
export function userRunApply(params) {
  return instance({
    url: `/api/goodsdrder/userRunApply`,
    method: 'post',
    data: params,
  })
}

// 我的卡券
export function coupon(params) {
  return instance({
    url: `/api/user/coupon`,
    method: 'post',
    params,
  })
}

// 申购支付
export function shengouPayJuhe(params) {
  return instance({
    url: `/api/shengou/pay_juhe`,
    method: 'post',
    params,
  })
}

// 申购支付
export function shengouCancle(params) {
  return instance({
    url: `/api/shengou/cancle`,
    method: 'post',
    params,
  })
}

// 申购支付
export function getShopIsAlowBuy(params) {
  return instance({
    url: `/api/shop/getShopIsAlowBuy`,
    method: 'get',
    params,
  })
}

// 申购支付
export function lowPrice(params) {
  return instance({
    url: `/api/shop/lowPrice`,
    method: 'get',
    params,
  })
}

// 抽奖奖品池
export function lotteryList(params) {
  return instance({
    url: `/api/lottery/list`,
    method: 'get',
    params,
  })
}

// 抽奖
export function lottery(data) {
  return instance({
    url: `/api/lottery/lottery`,
    method: 'post',
    data,
  })
}

// 抽奖记录
export function lotteryUserLog(params) {
  return instance({
    url: `/api/lottery/lotteryUserLog`,
    method: 'get',
    params,
  })
}

// 抽奖任务
export function lotteryTaskList(params) {
  return instance({
    url: `/api/lottery/lotteryTaskList`,
    method: 'get',
    params,
  })
}

// 抽奖任务
export function playtoKillPostion(params) {
  return instance({
    url: `/api/user/playtoKillPostion`,
    method: 'get',
    params,
  })
}

// 抽奖任务
export function anyActivityList(params) {
  return instance({
    url: `/api/webinfo/anyActivityList`,
    method: 'get',
    params,
  })
}

// 比赛列表
export function gamelist(params) {
  return instance({
    url: `/api/game/gamelist`,
    method: 'get',
    params,
  })
}

// 为心仪球队打CALL------获取比赛列表
export function getTeamCardgameStake(data) {
  return instance({
    url: `/api/game/getTeamCardgameStake`,
    method: 'post',
    data,
  })
}

// 竞猜
export function guessingPoint(data) {
  return instance({
    url: `/api/guessing/point`,
    method: 'post',
    data,
  })
}

// 竞猜记录
export function pointLog(data) {
  return instance({
    url: `/api/guessing/point_log`,
    method: 'post',
    data,
  })
}

// 为心仪球队打CALL------记录
export function getUserTeamCardgameStakLog(params) {
  return instance({
    url: `/api/gametrade/getUserTeamCardgameStakLog`,
    method: 'get',
    params,
  })
}

// 为心仪球队打CALL-----获取可以参加队伍的藏品
export function getJoinMatch(data) {
  return instance({
    url: `/api/gametrade/getJoinMatch`,
    method: 'post',
    data,
  })
}

// 为心仪球队打CALL------押注
export function teamCardgameStake(data) {
  return instance({
    url: `/api/gametrade/teamCardgameStake`,
    method: 'post',
    data,
  })
}

// 获取比赛规则
export function anyRuleInfo(params) {
  return instance({
    url: `/api/webinfo/anyRuleInfo`,
    method: 'get',
    params,
  })
}

// 世界杯奖池
export function partition(params) {
  return instance({
    url: `/api/game/partition`,
    method: 'get',
    params,
  })
}

// 藏品专辑列表
export function goodsAlbumList(params) {
  return instance({
    url: `/api/goodsAlbum/goodsAlbumList`,
    method: 'get',
    params,
  })
}

// 藏品专辑列表
export function goodsAlbumInfoById(params) {
  return instance({
    url: `/api/goodsAlbum/goodsAlbumInfoById`,
    method: 'get',
    params,
  })
}

// 排行榜
export function gameRanking(params) {
  return instance({
    url: `/api/game/gameRanking`,
    method: 'get',
    params,
  })
}

// 交子兑换专区列表
export function getPointComposeList(params) {
  return instance({
    url: `/api/goodsCompose/pointComposeList`,
    method: 'get',
    params,
  })
}

// 交子兑换用户合成记录
export function getPointComposeLogList(params) {
  return instance({
    url: `/api/user/pointComposeLogList`,
    method: 'get',
    params,
  })
}

// 交子兑换详情
export function getPointComposeById(params) {
  return instance({
    url: `/api/goodsCompose/pointComposeById`,
    method: 'get',
    params,
  })
}

// 交子合成记录详情
export function getpointComposeLogInfo(params) {
  return instance({
    url: `/api/user/getpointComposeLogInfo`,
    method: 'get',
    params,
  })
}

// 交子兑换进行合成
export function pointRunCompose(data) {
  return instance({
    url: `/api/user/pointRunCompose`,
    method: 'post',
    data,
  })
}

// 添加/编辑地址
export function addAdress(data) {
  return instance({
    url: `/api/user/addAdress`,
    method: 'post',
    data,
  })
}

// 地址列表
export function adressList(data) {
  return instance({
    url: `/api/user/adressList`,
    method: 'post',
    data,
  })
}

// 地址删除
export function delAdress(data) {
  return instance({
    url: `/api/user/delAdress`,
    method: 'post',
    data,
  })
}

// 地址删除
export function newUserRanking(params) {
  return instance({
    url: `/api/game/newUserRanking`,
    method: 'get',
    params,
  })
}

// 元宇宙宣传页
export function metaInfo(params) {
  return instance({
    url: `/api/meta/info`,
    method: 'get',
    params,
  })
}

// 元宇宙预约人数
export function joinPeople(params) {
  return instance({
    url: `/api/meta/joinPeople`,
    method: 'get',
    params,
  })
}

// 运费支付支付
export function runPayComposerPostage(data) {
  return instance({
    url: `/api/juhe/runPayComposerPostage`,
    method: 'post',
    data,
  })
}

// 交子提取邮费生成订单
export function payPostage(params) {
  return instance({
    url: `/api/user/payPostage`,
    method: 'get',
    params,
  })
}

// 生成ai图片
export function userCreatedGood(params) {
  return instance({
    url: `/api/user/userCreatedGood`,
    method: 'post',
    params,
  })
}

// 是否可以生成图片
export function aiPictureIsView(params) {
  return instance({
    url: `/api/advertCallback/aiPictureIsView`,
    method: 'get',
    params,
  })
}

// 获取用户状态
export function userBehaviorState(params) {
  return instance({
    url: `/api/user/userBehaviorState`,
    method: 'get',
    params,
  })
}

// 游戏初始化
export function runDawnGame(params) {
  return instance({
    url: `/api/dawnGame/RunDawnGame`,
    method: 'get',
    params,
  })
}

// 验证游戏
export function verifyGame(params) {
  return instance({
    url: `/api/dawnGame/verifyGame`,
    method: 'get',
    params,
  })
}

// 下一步游戏
export function nextGame(params) {
  return instance({
    url: `/api/dawnGame/nextGame`,
    method: 'get',
    params,
  })
}

// 游戏翻面
export function flip(params) {
  return instance({
    url: `/api/dawnGame/flip`,
    method: 'get',
    params,
  })
}

// 游戏翻面
export function createdAddress(params) {
  return instance({
    url: `/api/dawnGame/createdAddress`,
    method: 'post',
    params,
  })
}

// 查看用户锦囊
export function catSilkbag(params) {
  return instance({
    url: `/api/dawnGame/catSilkbag`,
    method: 'get',
    params,
  })
}

// 查看用户聊天次数
export function catUserAllowNum(params) {
  return instance({
    url: `api/gametrade/catUserAllowNum`,
    method: 'get',
    params,
  })
}

// 玫瑰花排行榜
export function rugosaRoseRanking(params) {
  return instance({
    url: `/api/game/rugosaRoseRanking`,
    method: 'get',
    params,
  })
}

// 发送用户聊天内容
export function addUserAiSay(params) {
  return instance({
    url: `api/gametrade/addUserAiSay`,
    method: 'post',
    params,
  })
}
// 玫瑰花排行榜
export function userHaveRole(params) {
  return instance({
    url: `/api/richGame/userHaveRole`,
    method: 'get',
    params,
  })
}

// 选择角色
export function choiceRole(params) {
  return instance({
    url: `/api/richGame/choiceRole`,
    method: 'post',
    params,
  })
}

// 是否选择角色
export function isChoiceRole(params) {
  return instance({
    url: `/api/richGame/isChoiceRole`,
    method: 'get',
    params,
  })
}

// 所有地点前三位玩家
export function monopolyInit(params) {
  return instance({
    url: `/api/richGame/monopolyInit`,
    method: 'get',
    params,
  })
}

// 游戏坐标
export function richPlace(params) {
  return instance({
    url: `/api/richGame/richPlace`,
    method: 'get',
    params,
  })
}

// 查看地点玩家
export function getPlaceUser(params) {
  return instance({
    url: `/api/richGame/getPlaceUser`,
    method: 'get',
    params,
  })
}

// 查看藏品名称是否存在
export function SeekGoodsName(params) {
  return instance({
    url: `api/chatGpt/SeekGoodsName`,
    method: 'get',
    params,
  })
}

// 支付扣款
export function ChatgptCreatedGood(params) {
  return instance({
    url: `api/chatGpt/ChatgptCreatedGood`,
    method: 'post',
    params,
  })
}

// 拉新排行榜
export function UserRanking(params) {
  return instance({
    url: `/api/game/UserRanking`,
    method: 'get',
    params,
  })
}

// 拉新排行榜
export function buyRankingList(params) {
  return instance({
    url: `/api/game/buyRankingList`,
    method: 'get',
    params,
  })
}

// 精灵是否可以作图
export function chatgptCreatePic(params) {
  return instance({
    url: `/api/chatGpt/ChatgptCreatePic`,
    method: 'get',
    params,
  })
}

// 精灵是否可以作图
export function getGameDate(params) {
  return instance({
    url: `/api/game/getGameDate`,
    method: 'get',
    params,
  })
}

// 订阅列表
export function subscribeList(params) {
  return instance({
    url: `/api/subscribe/subscribeGoodList`,
    method: 'get',
    params,
  })
}

// 订阅列表
export function blindBoxRedemptionVoucherList(params) {
  return instance({
    url: `/api/user/blindBoxRedemptionVoucherList`,
    method: 'get',
    params,
  })
}

// 订阅列表
export function blindBoxRedemptionVoucher(params) {
  return instance({
    url: `/api/user/blindBoxRedemptionVoucher`,
    method: 'get',
    params,
  })
}

// 订阅列表
export function prefixRechargePay(data) {
  return instance({
    url: `/api/HFPay/prefixRechargePay?token=${data.token}`,
    method: 'post',
    data,
  })
}

// 订阅列表
export function promotionList(params) {
  return instance({
    url: `/api/shop/promotionList`,
    method: 'get',
    params,
  })
}

// 订阅列表
export function mailBindTel(data) {
  return instance({
    url: `/api/user/mailBindTel`,
    method: 'post',
    data,
  })
}

// 订阅列表
export function FreezeConfig(data) {
  return instance({
    url: `/api/freeze/FreezeConfig`,
    method: 'post',
    data,
  })
}

// 订阅列表
export function userSetPostionFreeze(data) {
  return instance({
    url: `/api/freeze/userSetPostionFreeze`,
    method: 'post',
    data,
  })
}

// 订阅列表
export function getGoodsLabel(params) {
  return instance({
    url: `/api/shop/getGoodsLabel`,
    method: 'get',
    params,
  })
}

// gpt铸造价格
export function chatgptPrice(params) {
  return instance({
    url: `/api/webinfo/chatgptPrice`,
    method: 'get',
    params,
  })
}

// gpt铸造价格
export function getComposerTypeList(params) {
  return instance({
    url: `/api/goodsCompose/getComposerTypeList`,
    method: 'get',
    params,
  })
}

// 易宝钱包注册
export function createWallet(params) {
  return instance({
    url: `/api/YeePay/createWallet`,
    method: 'post',
    params,
  })
}

// 易宝钱包管理页面
export function userPayWallet(params) {
  return instance({
    url: `/api/YeePay/userPayWallet`,
    method: 'get',
    params,
  })
}

// 易宝钱包收银台统一下单
export function walletPay(params) {
  return instance({
    url: `/api/YeePay/walletPay`,
    method: 'post',
    params,
  })
}

// 易宝钱包收银台统一下单
export function getDrawInfo(params) {
  return instance({
    url: `/api/tradeAuth/getDrawInfo`,
    method: 'get',
    params,
  })
}

// 易宝钱包收银台统一下单
export function myInvite(params) {
  return instance({
    url: `/api/tradeAuth/myInvite`,
    method: 'get',
    params,
  })
}
// 易宝钱包收银台统一下单
export function userRunDraw(params) {
  return instance({
    url: `/api/tradeAuth/userRunDraw`,
    method: 'get',
    params,
  })
}
// 易宝钱包收银台统一下单
export function winCodeList(params) {
  return instance({
    url: `/api/tradeAuth/winCodeList`,
    method: 'get',
    params,
  })
}
// 易宝钱包收银台统一下单
export function geUserDraw(params) {
  return instance({
    url: `/api/tradeAuth/geUserDraw`,
    method: 'get',
    params,
  })
}
// 用户持有居民角色
export default {
  geUserDraw,
  winCodeList,
  userRunDraw,
  myInvite,
  getDrawInfo,
  createWallet,
  userPayWallet,
  walletPay,
  getComposerTypeList,
  getGoodsLabel,
  userSetPostionFreeze,
  FreezeConfig,
  mailBindTel,
  chatgptPrice,
  promotionList,
  prefixRechargePay,
  userRunbatchCompose,
  goodsbatchComposeById,
  blindBoxRedemptionVoucher,
  blindBoxRedemptionVoucherList,
  subscribeList,
  getGameDate,
  chatgptCreatePic,
  buyRankingList,
  UserRanking,
  ChatgptCreatedGood,
  SeekGoodsName,
  getPlaceUser,
  richPlace,
  monopolyInit,
  isChoiceRole,
  choiceRole,
  userHaveRole,
  rugosaRoseRanking,
  catSilkbag,
  createdAddress,
  flip,
  nextGame,
  verifyGame,
  runDawnGame,
  userBehaviorState,
  lowPrice,
  payPostage,
  runPayComposerPostage,
  joinPeople,
  metaInfo,
  delAdress,
  newUserRanking,
  adressList,
  addAdress,
  pointRunCompose,
  getPointComposeById,
  getpointComposeLogInfo,
  getPointComposeLogList,
  getPointComposeList,
  goodsAlbumInfoById,
  gameRanking,
  goodsAlbumList,
  partition,
  anyRuleInfo,
  teamCardgameStake,
  getTeamCardgameStake,
  getJoinMatch,
  getUserTeamCardgameStakLog,
  pointLog,
  guessingPoint,
  gamelist,
  lotteryUserLog,
  anyActivityList,
  playtoKillPostion,
  lotteryTaskList,
  lottery,
  lotteryList,
  getShopIsAlowBuy,
  payChannel,
  shengouCancle,
  coupon,
  shengouPayJuhe,
  userRunApply,
  userSignDays,
  applyGoodsList,
  applyGoodsInfo,
  noticeStatus,
  rewardWallet,
  userSignList,
  rewardWalletList,
  userCheckGoodsListCompose,
  userCheckGoodsListCompose1,
  rewardToUserMoney,
  unread,
  clearAll,
  orderStatus,
  popup,
  updateStatus,
  updateOrderStatus,
  withdrawal, // 我的钱包余额，提现
  goodsorderButton, //寄卖协议
  anyAlipayaccount, //我的支付宝
  blindBoxDetail, //盲盒详情
  detailsCleared, // 订单详情
  getopenBlindBoxList, // ，昂被
  rechargeok, // 充值完成列表
  // getUsergrant, //静默签署协议
  cancelUserOrder, //取消购买
  openBlindBox, //开启盲盒
  goodsorderDetail, //市场详情 ----- 确认订单
  anyGetPositionListByGoodsid, //市场详情------全部编号
  createUserhang, //卜卦
  anyCancel, //取消寄卖-----寄卖委托撤单;
  WithdrawalBalance, //我的----钱包提现
  anyBindalipayaccount, //绑定支付宝
  loop,
  goodsorder, // 我的订单
  blindBoxList, //首页 --- 盲盒
  Userinfo, //首页个人信息
  logout, // 退出登录
  ConsignmentSell, //确认寄卖
  ServiceCharge, //实时监听立即寄卖
  ConsignSell,
  anyCollectionDetail, //藏品详情
  anyHavegoodslist, //我的藏品
  payJuhe, //确认支付
  detail, ////首页商品详情-----立即购买
  recharge, //支付宝充值
  anyOriginPayPassword, // 修改支付密码
  anyPaypassword, //设置支付密码
  anyPassword, // 安全中心 - 修改登录密码;
  Banner,
  Recommend,
  Goodslist,
  getUserhang,
  getNotice,
  anyTypelist,
  login,
  getGraphicalCode,
  anyCheckcodeforget,
  affirm,
  getUserAgentInfo,
  getVerification,
  anyRegister,
  emailSubmit,
  getEmailVerification,
  getEmailGraphicalCode,
  loginTel,
  loginTU,
  anyGoodsdetail, // 商品详情
  anyGoodmarketlist, //市场商品列表
  anyGetGoodmarketInfoByid, // 市场商品详情
  anyHomeShopType, //首页分类列表
  anyGoodsUserhaveList, //首页创作人以及归属人
  anyNoticedetail, //banner详情
  anyIdcardadd, //设置----实名认证
  postImage,
  anyUserinfoedit, //
  userRealName, // 实名认证的状态
  anyUserinfo,
  anyBankname, // 获取银行卡信息
  anyBankcardadd, //绑定银行卡
  anyVerifyBankcard, // 验证银行卡短信
  anyBankcardlist, // 用户银行卡列表
  anyInfo, //基本设置
  getInvitationList, // 我的邀请人列表
  set_up, //安全中心-设置登录密码
  getNoticeTypeList,
  pendingPayment,
  passwordForget,
  anyCheckcodeforgetForEmail,
  getMarketSearchResult,
  userBuyShopList,
  userPostionGoodNumberList,
  poster,
  feedback,
  goodsComposeList,
  goodsComposeById,
  userGoodsCompose,
  userRunCompose,
  userComposeLogList,
  getUserComposeLogInfo,
  shopHotWordList,
  userCreatedGood,
  aiPictureIsView,
  catUserAllowNum,
  addUserAiSay,
  getUserSpeciality,
}
