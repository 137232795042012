<template>
  <div class="loading">
    <div class="content">
      <img src="../../assets/images/loading.png" alt="" />
      <div class="info">页面加载中...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang="less" scoped>
.loading {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: fixed;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
    .info {
      font-size: 14px;
      color: #b5babf;
    }
  }
}
</style>
