import CryptoJS from "crypto-js";
import qs from "qs";
// 计算出时间戳的具体数据：比如将85400转化为 n时n分n秒
export function formateTimeStamp(timeStamp) {
  var day;
  var hour;
  var min;
  var seconds;

  day = parseInt(timeStamp / (60 * 60 * 24)); // 计算整天数
  var afterDay = timeStamp - day * 60 * 60 * 24; // 取得算出天数后剩余的秒数
  hour = parseInt(afterDay / (60 * 60)); // 计算整数小时数
  var afterHour = timeStamp - day * 60 * 60 * 24 - hour * 60 * 60; // 取得算出小时数后剩余的秒数
  min = parseInt(afterHour / 60); // 计算整数分
  seconds = parseInt(
    timeStamp - day * 60 * 60 * 24 - hour * 60 * 60 - min * 60,
  ); // 取得算出分后剩余的秒数

  if (hour < 10) {
    hour = '0' + hour;
  }

  if (min < 10) {
    min = '0' + min;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  const restTime = {
    day: day,
    hour: hour,
    min: min,
    seconds: seconds,
  };
  return restTime;
}

export function isSafari() {
  var ua = navigator.userAgent.toLowerCase();
  if (
    ua.indexOf('applewebkit') > -1 &&
    ua.indexOf('mobile') > -1 &&
    ua.indexOf('safari') > -1 &&
    ua.indexOf('linux') === -1 &&
    ua.indexOf('android') === -1 &&
    ua.indexOf('chrome') === -1 &&
    ua.indexOf('ios') === -1 &&
    ua.indexOf('browser') === -1 &&
    ua.indexOf('qqbrowse') === -1
  ) {
    return true;
  } else {
    return false;
  }
}

export function isWeiXin() {
  //获取访问终端的版本信息,如果终端信息中存在微信标识' micromessenger ',则是微信
      var ua = window.navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            return true;
      }else{
          return false;
      }
 }

 const secretKey = CryptoJS.enc.Utf8.parse("9cd80a3eaae891d652b894f77e9516a3");
 export function getData(res) {
  const query = Object.assign({}, res)
  query.time = parseInt(Date.now() / 1000)
  const iv = CryptoJS.lib.WordArray.random(8).toString(CryptoJS.enc.Hex); // "8905d45e1f8ada88"
  // openssl
  var openssl = CryptoJS.AES.encrypt(qs.stringify(query), secretKey, {
    mode: CryptoJS.mode.CBC,
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
  });
  const hmac = CryptoJS.HmacSHA256(openssl.ciphertext, secretKey).toString(
    CryptoJS.enc.Base64
  );
  const obj = {
    iv: iv.toString(),
    hmac: hmac,
    openssl: openssl.toString(),
  };
  const data = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(JSON.stringify(obj))
  );
  return data.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "")
 }

 export function formate(data) {
  let res = data.replaceAll("-", "+").replaceAll("_", "/")
  const len = 3-(3+res.length)%4
  return res.padEnd(res.length+len, '=')
 }

export function formateData(data) {
  const res = CryptoJS.enc.Base64.parse(formate(data));
  
  const aa = JSON.parse(CryptoJS.enc.Utf8.stringify(res));
  const bb = CryptoJS.AES.decrypt(aa.openssl, secretKey, {
    mode: CryptoJS.mode.CBC,
    iv: CryptoJS.enc.Utf8.parse(aa.iv),
    padding: CryptoJS.pad.Pkcs7,
  });
  // console.log(JSON.parse(CryptoJS.enc.Utf8.stringify(bb)));
  return JSON.parse(CryptoJS.enc.Utf8.stringify(bb))
}