import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    needGoods: {},
    isFromHome: 0,
    anyInfo: {},
  },
  getters: {},
  mutations: {
    SET_NEEDGOOD(state, val) {
      state.needGoods = val;
    },
    REMOVE_NEEDGOODS(state) {
      state.needGoods = {};
    },
    SET_ISFROMHOME(state, val) {
      state.isFromHome = val;
    },
    SET_ANYINFO(state, val) {
      state.anyInfo = val;
    },
  },
  actions: {},
  modules: {},
});
