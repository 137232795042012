<template>
  <div class="topHeader" :style="{ background: isNoBg ? isNoBg : '#ffffff' }">
    <div class="left" @click="click" v-if="!isHideLeft">
      <!-- <img src="../../../static/image/left.png" @click="back" alt="" /> -->
      <i
        @click="back"
        class="leftIcon"
        :class="theme === 'light' ? null : 'dark'"
      ></i>
    </div>
    <div class="order_text" :class="theme === 'light' ? null : 'dark'">
      <slot />
    </div>
    <div class="right" @click="$emit('clickRight')"><slot name="right" /></div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    backUrl: String,
    callBack: Boolean,
    isHideLeft: Boolean,
    isNoBg: Boolean | String,
    theme: {
      type: String,
      default: 'light',
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
    back() {
      if (this.callBack) return;
      if (this.backUrl) {
        this.$router.push(this.backUrl);
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.topHeader {
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 11px;
      height: 20px;
    }
    .leftIcon {
      width: 13px;
      height: 13px;
      display: block;
      transform: rotate(-45deg);
      border-top: 2px solid #000;
      border-left: 2px solid #000;
      &.dark {
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
      }
    }
  }
  .right {
    position: absolute;
    transform: translateY(-50%);
    right: 16px;
    top: 50%;
    font-size: 12px;
  }
  .order_text {
    width: 50%;
    font-size: 18px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.dark {
      color: #fff;
    }
  }
}
</style>
